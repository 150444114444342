import React from "react"
import { Link } from "gatsby"
import BGImage from "gatsby-background-image"
import styled from "styled-components"
import { colors, B1Black, buttonThree } from "../../styles/helpers"
import { createSlug } from "../../utils/helperFunc"

const HeroSection = styled.section`
  .hero-wrapper {
    position: relative;
    min-height: 50rem;
    @media (min-width: 768px) {
      min-height: 65rem;
    }

    @media (min-width: 1025px) {
      min-height: 75rem;
    }
  }

  .hero-content {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 100%;
    transform: translate(50%, -50%);
    z-index: 10;

    @media (min-width: 768px) {
      right: 35%;
      max-width: 50vw;
      transform: translate(50%, -50%);
    }

    @media (min-width: 1025px) {
      max-width: 50vw;
    }

    &__inner {
      padding: 4rem;
      background: ${colors.white};

      @media (min-width: 1025px) {
        padding-top: 10rem;
        padding-right: 15rem;
        padding-bottom: 10rem;
        padding-left: 5rem;
      }
    }

    &__filter {
      position: absolute;
      top: 2rem;
      left: -2rem;
      bottom: -2rem;
      width: 100%;
      background: ${colors.colorAlt};
      opacity: 0.44;
      z-index: -1;
    }

    p {
      ${B1Black};
    }

    &__button {
      @media (min-width: 1025px) {
        margin-top: 5rem;
      }

      a {
        ${buttonThree};
      }
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media (min-width: 1025px) {
      width: auto;
      right: 25vw;
    }

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const HeroSimple = props => {
  const {
    hero: { acf: hero },
  } = props

  const heroFluid = hero._coo_hosim_bg.localFile.childImageSharp.fluid
  const heroContent = hero._coo_hosim_content
  const heroBtnText = hero._coo_hosim_btn_text
  const btnReq = hero._cco_hosim_button_required
  const heroBtnSlug = hero._coo_hosim_page_link_two

  const linkUrl = createSlug(heroBtnSlug)

  return (
    <HeroSection>
      <div className="hero-wrapper">
        <div className="hero-content">
          <div className="hero-content__inner">
            <div dangerouslySetInnerHTML={{ __html: heroContent }} />
            {btnReq && (
              <div className="hero-content__button">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cooperscrossingresidents.com/"
                >
                  {heroBtnText}
                </a>
              </div>
            )}
          </div>
          <div className="hero-content__filter" />
        </div>

        <div className="background-image">
          <BGImage tag="div" fluid={heroFluid} />
        </div>
      </div>
    </HeroSection>
  )
}

export default HeroSimple
