import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import TopQuote from "../components/ResAssociation/TopQuote"
import Hero from "../components/ResAssociation/Hero"
import ContentBlock from "../components/ResAssociation/ContentBlock"
import Gallery from "../components/ResAssociation/Gallery"

const ResidentsAssociation = props => {
  const { seoInfo, topQuote, hero, conBlock, gallery } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <TopQuote topQuote={topQuote} />
      <Hero hero={hero} />
      <ContentBlock data={conBlock} />
      <Gallery data={gallery} />
    </Layout>
  )
}

export const resAssociationQuery = graphql`
  query resAssociationPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }

    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hosim_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hosim_content
        _cco_hosim_button_required
        _coo_hosim_btn_text
        _coo_hosim_page_link_two
      }
    }

    conBlock: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_conblo_content
        _coo_conblo_bottom_callout
      }
    }

    gallery: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_galimg_gallery {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default ResidentsAssociation
