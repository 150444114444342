import React from "react"
import styled from "styled-components"
import {
  standardWrapper,
  bodyCopyTwo,
  colors,
  headlineThree,
  B2Black,
  B1Black,
  H3White,
} from "../../styles/helpers"

const ContentBlockSection = styled.div`
  margin-top: 2.5rem;

  @media (min-width: 768px) {
    margin-top: 5rem;
  }

  .wrapper {
    ${standardWrapper};
  }

  .content {
    p {
      ${B1Black};
    }
  }

  .contentBottom {
    padding: 3rem 0;
    background-color: ${colors.colorSecondary};
    text-align: center;

    @media (min-width: 768px) {
      padding: 5rem 0;
    }

    &__wrapper {
      ${standardWrapper};
    }

    p {
      ${H3White};
      color: ${colors.colorAccent};

      &:last-of-type {
        margin: 0;
      }
    }
  }
`

const ContentBlock = ({ data }) => {
  const content = data.acf._coo_conblo_content
  const contentBottom = data.acf._coo_conblo_bottom_callout
  return (
    <ContentBlockSection>
      <div className="wrapper">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <div className="contentBottom">
        <div
          className="contentBottom__wrapper"
          dangerouslySetInnerHTML={{ __html: contentBottom }}
        />
      </div>
    </ContentBlockSection>
  )
}

export default ContentBlock
