import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { medWrapper } from "../../styles/helpers"

const GallerySection = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;

  .wrapper {
    ${medWrapper};
  }

  .galleyWrap {
    columns: 2 400px;

    @media (min-width: 768px) {
      columns: 2 350px;
      column-gap: 1rem;
    }

    @media (min-width: 1025px) {
      columns: 2 500px;
      column-gap: 1rem;
    }
  }

  .galleryImage {
    display: inline-block;
    width: 100%;
    margin: 0 1rem 1rem 0;
  }

  @for $i from 1 through 36 {
    &:nth-child(#{$i}) {
      $h: (random(400) + 100) + px;
      height: $h;
    }
  }
`

const Gallery = ({ data }) => {
  const images = data.acf._coo_galimg_gallery
  return (
    <GallerySection>
      <div className="wrapper">
        <div className="galleyWrap">
          {images.map((img, index) => {
            return (
              <div className="galleryImage" key={index}>
                <Img
                  fluid={img.localFile.childImageSharp.fluid}
                  alt={img.alt_text}
                />
              </div>
            )
          })}
        </div>
      </div>
    </GallerySection>
  )
}

export default Gallery
